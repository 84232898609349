import API from '@axios'
import { apiHelper } from '../helpers/apiHelper'

import { stringify as toQuery } from 'query-string'

const API_URL = '/api/dashboards'

// export default {
//   ...apiHelper(API_URL),
//   getAllScopes() {
//     return API.get(`${API_URL}/scopes`)
//   },
//   updateSecret(payload) {
//     return API.put(`${API_URL}/${payload.id}/change_client_secret`, payload.data)
//   },
// }

export const dashboardService = tableName => ({
  ...apiHelper(`${API_URL}/${tableName}`),
  searchDataSource(search) {
    return API.get(`${API_URL}/${tableName}?${toQuery({
      page: 0,
      q: search,
      size: 200,
    })}`)
  },

  dataSourceEntities() {
    return API.get(`${API_URL}/datasource/entities`)
  },

  dashboarView(id) {
    return API.get(`${API_URL}/view/${id}`)
  },

  getMyCategorizedDashboard() {
    return API.get(`${API_URL}/categories/view?mainList=true`)
  },

  getMyCategorizedDashboardCompany() {
    return API.get(`${API_URL}/categories/view?showInCompany=true&mainList=false`)
  },

  getMyCategorizedDashboardContact() {
    return API.get(`${API_URL}/categories/view?showInContact=true&mainList=false`)
  },

  searchGroup(search) {
    return API.get(`/api/core/groups?${toQuery({
      page: 0,
      q: search,
      size: 200,
    })}`)
  },

  getAllWidgetData() {
    return API.get(`${API_URL}/widget?${toQuery({
      page: 999999,
    })}`)
  },

  getWidgetData(id, payload = null) {
    return API.post(`${API_URL}/widget/${id}/data`, payload)
  },
  getDashboardWidgetData(dashboardId, widgetId, payload = null) {
    return API.post(`${API_URL}/view/${dashboardId}/widgets/${widgetId}`, payload)
  },
  getDashboardWidgetSpecificData(dashboardId, widgetId, entityId, uuidKey) {
    return API.get(`${API_URL}/view/${dashboardId}/widgets/${widgetId}/${entityId}/${uuidKey}`)
  },
  getDashboardWidgetSpecificDataWithPayload(dashboardId, widgetId, entityId, uuidKey, payload) {
    return API.post(`${API_URL}/view/${dashboardId}/widgets/${widgetId}/${entityId}/${uuidKey}`, payload)
  },
  getWidgetSpecificData(widgetId, entity, uuidKey) {
    return API.get(`${API_URL}/widget-specific/${widgetId}/${entity}/${uuidKey}`)
  },
  dataSourceFields(id) {
    return API.get(`${API_URL}/datasource/fields/${id}`)
  },
  saveAdvancedDatasource(payload) {
    return API.post(`${API_URL}/datasource-advanced`, payload)
  },
  getAdvancedDatasource(uuid) {
    return API.get(`${API_URL}/datasource-advanced/${uuid}`)
  },
  saveAdvancedWidget(payload) {
    return API.post(`${API_URL}/widget`, payload)
  },
  updateAdvancedWidget(uuid, payload) {
    return API.put(`${API_URL}/widget/${uuid}`, payload)
  },
  getWidget(uuid) {
    return API.get(`${API_URL}/widget/${uuid}`)
  },
})
