import { defaultStateFactory } from '@/store/advanced-search/state'
import prozessEntitySelectorMutation from '@/store/shared/prozessEntitySelectorMutation'

export default {
  ...prozessEntitySelectorMutation(defaultStateFactory),

  ADD_FIELD_OPTION(state, entityId) {
    const entity = state.entities.find(_entity => _entity.id === entityId)
    state.fieldOptions.push({
      entityId,
      collapsed: true,
      schema: entity.schema,
      key: entity.key,
      tableLabel: entity.label,
      fields: entity.columns.map(column => ({
        ...column,
        entitySchema: entity.schema,
        entityKey: entity.key,
        filtering: false,
        selected: false,
        deselectable: true,
        filterValue: null,
        filterCondition: null,
      })),
    })
  },
  SET_DATE_FILTER(state, payload) {
   const dateFilterIndex = state.dateFilters.findIndex(_option => _option.entityId === payload.entityId)
    if (dateFilterIndex < 0) {
      state.dateFilters.push(payload)
    } else {
      state.dateFilters[dateFilterIndex] = payload
    }
    state.fieldOptions = JSON.parse(JSON.stringify(state.fieldOptions))
  },
}
