export const defaultStateFactory = () => ({
  entities: [],
  selectedEntities: [],
  dateFilters: [],
  fieldOptions: [],
  result: {
    allItemsCount: 0,
    filteredItemsCount: 0,
    pageItems: [],
  },
  errors: [],
})

export default () => (defaultStateFactory())
